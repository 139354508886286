import React, { useEffect, useContext, useRef, Fragment, useState } from "react";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Sidebar from '../../components/common/ArticleSidebar'
import SocialMedia from '../../components/common/SocialMedia'
import lsrImg from '../../assets/img/pages/vr-games/VIRSAT-Life-Saving-Rules-Aware.jpg'
import ogImg from '../../assets/img/pages/articles/VR Games A New Way to Create Significant Safety Impact.jpg'

function VrGamesSignficant({location}) {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);
  const slug = useState(location.pathname.split('/')[2]);
  const [pageTitle] = useState('VR Games: A New Way to Create Significant Safety Impact')

  let endDiv = useRef(null);
  const [endDivPosition, setEndDivPosition] = useState(0);

  const updatePosition = () => {
    setEndDivPosition(endDiv.current.getBoundingClientRect().top)
  };

  useEffect(() => {
    window.addEventListener("scroll", updatePosition);
    
    return () => window.removeEventListener("scroll", updatePosition);
  }, [endDivPosition]);


  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Fragment>
      <Layout>
        <Seo
          title={pageTitle}
          description='The features of VR are explained in the context of adult learning & how it can be used to create improved safety training for the workforce.'
          image={ogImg}
          ogType='article'
        />
        <PageBanner title='Articles' useDiv={true}/>
        <section
          className='main-container single-article hide-container'
          ref={container}
        >
          <div className='row less-mar bottom-pad'>
            <SocialMedia 
              pageUrl={location.href} 
              pageTitle={pageTitle}
              endDivPosition={endDivPosition}
            />
            <article className='column article-details'>
              <header>
                <h1 className="main-title small primary-color">
                  <strong>VR Games: A New Way to Create Significant Safety Impact</strong>
                </h1>
                <div className='author-date'>
                  By <strong>Willem Peuscher</strong>
                  <span className='spacer'>|</span>October 05, 2021
                  <span className='spacer'>|</span>2 minute read
                </div>
              </header>
              <div 
                // onClick={()=>handeleActiveImg(vrGamesImg)} 
                aria-hidden className='article-main-image no-zoom'>
                <StaticImage
                  src='../../assets/img/pages/articles/VR Games A New Way to Create Significant Safety Impact.jpg'
                  alt='VR Games A New Way to Create Significant Safety Impact'
                  title='VR Games A New Way to Create Significant Safety Impact'
                  quality="100"
                  objectFit
                  className='gatsby-image'
                />
              </div>

              <p className="justify">
                <strong>Abstract:</strong> In this article, the features of VR are explained in the context of adult learning and how it can be used to create improved safety training for the workforce and, thus, impacting companies’ safety performance.
              </p>
              <p className="justify">
              <strong>Wikipedia defines VR as: </strong>“Virtual reality (VR) is a simulated experience that can be similar to or completely different from the real world. Applications include entertainment (video games), education, and business (e.g. virtual meetings). With “Immersive” VR, your view is changed when you move your head. A person using virtual reality equipment is able to look around the artificial world, move around in it, and interact with virtual features or items. Virtual reality typically incorporates auditory and video feedback, but may also allow other types of sensory and force feedback through haptic technology” <Link to="#link-group"><sup>[1]</sup></Link>.
              </p>

              <h2 className="main-subtitle no-mar-bottom">Adult Learning</h2>
              <p className="justify mar-bottom">
              From <strong>a Historic Cambridge</strong> research, it was learned that Malcolm Knowles (1980) tried to document the differences between the ways adults and children learn, and developed the concept of <i>Andragogy</i> (“The art and science of helping adults learn”), and compared this with <i>Pedagogy</i> (“The art and science of teaching children”). He postulated a number of assumptions about adult learners and defined conditions of learning and the relevant principles of teaching. It was concluded that adults learn best by “Doing”, when “The learning environment gives physical comfort, mutual trust and respect, mutual helpfulness, freedom of expression, and acceptance of differences”, and when “The learning process is related to and makes use of the experience of the learners” <Link to="#link-group"><sup>[2]</sup></Link>.
              </p>

              <div 
                // onClick={()=>handeleActiveImg(vrGamesImg)} 
                aria-hidden className='article-image no-zoom'>
                <StaticImage
                  src='../../assets/img/pages/articles/Adult Learning.jpg'
                  alt='Adult Learning'
                  title='Adult Learning'
                  quality="100"
                  className="article-gatsby-image"
                />
              </div>

              <h2 className="main-subtitle no-mar-bottom">VR in Adult Learning</h2>
              <p className='justify'>VR is an excellent adult learning methodology. It implements all aspects published by Knowles. VR immerses learners in the learning environment. They quickly adapt themselves to the virtual world and can practice activities and actions. The VR environment allows the learner to make mistakes, then do things again until the learners are satisfied. It doesn’t remind learners of past classroom experiences, as VR is a new way of learning. In this way, it avoids past frustrations that many practical workers have against conventional training. It gives the learners a sense of pride to complete a VR learning module, as it is a new and sophisticated technique. The learners can use their experience to deal with certain situations in the VR world and be proud of the demonstrated competence. When making mistakes, the learner can do the exercise again, without negative perceptions of classmates-- learning through doing.</p>

              <h2 className="main-subtitle no-mar-bottom">VR Compared with Classroom Training</h2>
              <p className='justify'>Many studies have been done to compare VR with other techniques. Recent research by PWC <Link to="#link-group"><sup>[3]</sup></Link> has demonstrated that VR learners were: 4X faster than those in the classroom, 275% more confident to apply skills learned after training, 3.75 times more emotionally connected to content than classroom learners, and 4X more focused that their e-learning peers.</p>
              <p className='justify'>The trainees are completely focused on the game when wearing the headset, without any distraction. During the game, the trainee practices various skills. This ‘learn by doing’ is a top quartile learning method for adults.</p>
              <p className='justify mar-bottom'>These beneficial VR facts offer unique opportunities for companies who need to train their workers. Many companies are now considering VR as a learning tool.</p>

              <div 
                // onClick={()=>handeleActiveImg(vrGamesImg)} 
                aria-hidden className='article-image no-zoom'>
                <StaticImage
                  src='../../assets/img/pages/articles/VR Compared with Classroom Training.jpg'
                  alt='Adult Learning'
                  title='Adult Learning'
                  quality="100"
                  className="article-gatsby-image"
                />
              </div>

              <h2 className="main-subtitle no-mar-bottom">How do you create learning that creates significant safety impact?</h2>
              <p className='justify'>With this question in mind, VIRSAT LLC designed safety VR learning games for employees and craftsman, using the Life-Saving Rules as basis <Link to="#link-group"><sup>[4]</sup></Link>. With these games, the workforce can practice their skills on the most hazardous jobs in their respective industries without any risk. The games build on the experience of the workers and help them to take the right actions. In a friendly way, their competence is strengthened and confirmed. Dependent on the level of comprehension required, the games are designed as playful or are more serious in nature.</p>

              <div>
                <ul className="links-anchors" id="link-group">
                  <li>
                    <a href="https://en.wikipedia.org/wiki/Virtual_reality_applications">[1] https://en.wikipedia.org/wiki/Virtual_reality_applications</a>
                  </li>
                  <li>
                    <span className='primary-color'>[2]</span> Knowles, M. (1980). The modern practice of adult education: Andragogy versus pedagogy. Rev. and updated ed. Englewood Cliffs, NJ: Cambridge Adult Education.
                    <a href="http://www.colllearning.info/wp-content/uploads/2019/03/The-Modern-Practice-of-Adult-Education.pdf">http://www.colllearning.info/wp-content/uploads/2019/03/The-Modern-Practice-of-Adult-Education.pdf</a>
                  </li>
                  <li>
                    <span className='primary-color'>[3]</span> PWC
                    <a href="https://www.pwc.com/us/en/services/consulting/technology/emerging-technology/vr-study-2020.html"> The VR Advantage: How virtual reality is redefining soft skills training</a>
                  </li>
                  <li>
                    <span className='primary-color'>[4]</span> The implementation of the Life-Saving Rules has shown to create significant safety impact, see article VIRSAT: 
                    <Link to="/articles/vr-games-new-way-make-work-significantly-safer"> VR Games: A New Way to Make Work Significantly Safer</Link>
                  </li>
                </ul>
              </div>

              <div className="footer-note primary-bg">
                <div>
                  <p className='no-mar'>
                  If you want to learn more, see the brochure - <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf">Life-Saving Rules Aware-Knowledge</a>, or write to <a href="mailto:info@virsat.com">info@virsat.com</a>. 
                  </p>
                </div>
                <div>
                  <a target="blank" href="https://secure.virsat.com/pdf/VIRSAT-Life-Saving-Rules-Aware.pdf"><img src={lsrImg} alt='VIRSAT Life Saving Rules Aware'/></a>
                </div>
              </div>

              {/* <div className="subscribe-container">
                <p>To subscribe to the newsletter, visit <Link to='/lp/newsletter/'>https://virsat.com/lp/newsletter/</Link>.</p>
                <Link className="button-link secondary" to="/lp/newsletter">Subscibe Me</Link>
              </div> */}
              <div style={{height:'1px','width':'1px'}} ref={endDiv}/>
            </article>
            <Sidebar slug={slug} endDivPosition={endDivPosition}/>
          </div>
        </section>
      </Layout>
    </Fragment>
  )
}

export default VrGamesSignficant
